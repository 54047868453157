import { translate } from '@/utils'
import Image from 'next/image'
import Link from 'next/link'
import React, { useState } from 'react'
import { CardSubtitle, CardTitle } from 'react-bootstrap';
import Dialog from '@mui/material/Dialog';
import { DialogContent, Backdrop } from '@mui/material';
import image from '../../assets/SilderImages/Silder-image-1.jpg'
import silderImage from '../../assets/SilderImages/image_1.jpg'
import icon from '../../assets/svg/Icon.svg';
import closeLine from '../../assets/svg/close-line.svg'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import rightArrow from '../../assets/svg/arrow-right-s-line (2).svg';
import leftArrow from '../../assets/svg/arrow-right-s-linee.svg'

import pgIcon from "@/assets/icons/pg.png";
import vrIcon from "@/assets/icons/vr.png";
import pmIcon from "@/assets/icons/pm.png";

import { useTranslation } from 'react-i18next';

const OurServices = () => {

  const { t } = useTranslation();
  const services = [
    {
      image: pgIcon,
      title: "professionalPhotography",
      subtitle: "serviceBody1",
      url: "#",
      onclick : "Professional Photography",
    },
    {
      image: vrIcon,
      title: "virtualTour",
      subtitle: "serviceBody1",
      url: "#",
      onclick : "Virtual Tour",
    },
    {
      image: pmIcon,
      title: "Property Staging",
      subtitle: "serviceBody1",
      url: "#",
      onclick : "Property Management",
    }
  ]

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleDialogOpen = (service) => {
    setSelectedService(service);
    setDialogOpen(true);
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const images = [
    silderImage?.src,
    image?.src,
  ];


  const Slider = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);


    const goToPrevSlide = () => {
      const newIndex = (currentIndex - 1 + images.length) % images.length;
      setCurrentIndex(newIndex);
    };

    const goToNextSlide = () => {
      const newIndex = (currentIndex + 1) % images.length;
      setCurrentIndex(newIndex);
    };

    return (
      <div className="slider-container">
        <button className="left-arrow" onClick={goToPrevSlide}>
          <img src={rightArrow?.src} />
        </button>
        <img className="slider-image" src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} />
        <button className="right-arrow" onClick={goToNextSlide}>
          <img src={leftArrow?.src} />
        </button>
        <div className="dot-container">
          {images.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentIndex ? 'active rounded-full border border-white-500 px-3' : 'bg-gray-600'}`}
            // onClick={() => goToSlide(index)}
            ></span>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>

      <section id="ourServices" className='page-section'>
        <div className="container">
          <p className="section-title h3">{translate('ourServices')}</p>
          <ul className="serv_grid">
            {
              services && services.map((item, idx) => (
                <li className="card" key={idx}>
                  <div className="card-body">
                    <Image
                      src={item.image}
                      alt={`${translate(item.title)} Icon Services`}
                      width={32}
                      height={32}
                    />
                    <CardTitle>{translate(item.title)}</CardTitle>
                    <CardSubtitle>{translate(item.subtitle)}</CardSubtitle>

                    <div className="card-footer">
                      <button className='button button-outline' onClick={() => handleDialogOpen(item.onclick)} aria-label={`Learn more about ${item.title}`} disabled={idx === 2}>
                        {translate('seeMore')}
                        <span className="hidden-text-seo">Learn more about Service {item.title}</span>
                      </button>
                    </div>
                  </div>
                </li>
              ))
            }
          </ul>
        </div>
      </section>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        PaperProps={{
          sx: {
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            zIndex: 1000,
            width: {
              xs: '100%',
              sm: '75%',
            },
            maxWidth: 'none',
            margin: '10px',
            // margin: 'auto'
          },
        }}
      >
        <DialogContent>
          <div className='flex flex-col md:flex-row md:justify-between'>
            <div className='w-full h-full  md:w-1/2'>
              <Slider images={images} />
            </div>

            <div className='w-full md:w-1/2 mx-2 p-2'>
              <div className='flex justify-between items-center mb-4'>
                <img src={icon?.src} alt='icon not found' />
                <img src={closeLine?.src} alt='close line icon not found' className='closeLine cursor-pointer' onClick={handleDialogClose} />
              </div>

              <div className='dialog-contain'>
                <h1 className="text-xl font-semibold">{t('Professional-Photography')}</h1>
                <p className='whitespace-normal'>{t('Virtual-Tour')}</p>
              </div>

              <div className='benefit-contain'>
                <h3 className="text-lg font-semibold">{t('Benefit use this service')}</h3>
                <div className='benefit-card'>
                  <div>
                    <p>{t('Shoot')}</p>
                  </div>
                  <div>

                    <p>{t('Shoot')}</p>
                  </div>
                  <div>

                    <p>{t('Shoot')}</p>
                  </div>
                  <div>

                    <p>{t('Shoot')}</p>
                  </div>
                </div>
              </div>

              <button className='w-full btn-request mt-4'>{t('Request Service')}
                <ArrowForwardIcon className='ml-2' />
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Backdrop open={dialogOpen} sx={{ zIndex: 999 }} />
    </>
  )
}

export default OurServices
